import React, { useEffect, useState, useRef } from "react"
import tw, { styled } from "twin.macro"
import Logo from "@root/Logo"
import { motion } from "framer-motion"
import { navigate, Link } from "gatsby"
import throttle from "lodash-es/debounce"
import { useStateContext } from "@context/stateContext"
import { globalHistory as history } from "@reach/router"

const isBrowser = () => typeof window !== "undefined"

const DesktopNavigationContainer = styled(motion.div)`
  ${tw`absolute inset-0 text-white font-primary`}
  height: 135px;
`

const SiteTitle = styled(motion.span)`
  ${tw`absolute z-10 pl-10 font-black uppercase pt-[3.25rem] drop-shadow-xl`}
`

const Nav = styled.nav`
  ${tw`absolute flex flex-row items-center justify-end w-full pr-10 pt-[3.25rem]`}
  ${({ windowHeight }) => `height: ${windowHeight}px`}
`

const Ul = styled(motion.ul)`
  ${tw`relative flex flex-row`}
`

// TODO: Integrate menue font-sizes in Tailwind Theme

const Li = styled(motion.li)`
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 2rem;
  padding-bottom: 20px;

  &::before {
    content: "";
    ${tw`w-5 h-5 rotate-45 -translate-x-1/2 bg-white opacity-0 -bottom-2 left-1/2`}
  }

  &::after {
    content: "";
    ${tw`transition duration-500 ease-in-out`}
  }

  &.menu-active {
    ${tw`relative`}

    &::after {
      ${tw`absolute left-0 right-0 h-px bg-current bottom-5`}
    }
  }

  &.has-child:hover {
    ${tw`relative`}

    &::before {
      ${tw`absolute opacity-100 z-[777]`}
      box-shadow: -3px -3px 6px -3px rgba(0,0,0,.5);
    }
  }
`

const Button = styled(motion.button)`
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &::after {
    content: "";
    ${tw`transition duration-500 ease-in-out`}
  }
  &.menu-active {
    ${tw`relative`}

    &::after {
      ${tw`absolute bottom-0 left-0 right-0 h-px bg-current`}
    }
  }
`

const NestedNavigationWrapper = styled(motion.div)`
  ${tw`absolute z-50 p-5 text-black bg-white font-primary`}
  top: 40px;
  left: -400px;
  height: auto;
  min-height: 84px;
  overflow: hidden;
  width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  &.only-second-level {
    left: -196px;
    width: 296px;
  }

  &:hover {
    ${tw`block`}
  }
`
const SecondLevelNavigation = styled(motion.div)`
  ${tw`flex flex-col items-start justify-start px-5 font-medium text-left text-black bg-white font-primary`}
  top: 0;
  left: 0;
  border-right: 1px solid #555;
  width: 60%;
  height: 100%;

  &:hover {
    ${tw`flex`}
  }

  &.only-second-level-nav {
    width: 100%;
    border-right: none;
  }

  a {
    ${tw`my-2`}
  }
  a.second-level-active {
    ${tw`relative font-black w-max`}
    &::after {
      ${tw`content-[''] absolute bottom-[-2px] left-0 right-0 h-[4px] bg-yellow`}
    }
  }
`
const ThirdLevelNavigation = styled(motion.div)`
  ${tw`absolute bg-white !text-base text-black text-left font-medium font-primary p-5 z-[888]`}
  top: 0;
  right: 0;
  height: 100%;
  height: max-content;
  width: 204px;
  text-transform: none;

  &:hover {
    ${tw`block`}
  }
  a {
    ${tw`!my-2`}
  }
  a.third-level-active {
    ${tw`relative font-bold w-max`}
    &::after {
      ${tw`content-[''] absolute -bottom-1 left-0 right-0 h-[4px] bg-yellow`}
    }
  }
`

const variantsNavigationContainer = {
  menuHidden: {
    backgroundColor: "rgba(255,255,255,0)",
    backdropFilter: "blur(50px) opacity(0)",
  },
  menuVisible: {
    backgroundColor: "rgba(255,255,255,.5)",
    backdropFilter: "blur(50px) opacity(1)",
  },
  menuHiddenDark: {
    backgroundColor: "rgba(0,0,0,0)",
    backdropFilter: "blur(50px) opacity(0)",
  },
  menuVisibleDark: {
    backgroundColor: "rgba(0,0,0,.5)",
    backdropFilter: "blur(50px) opacity(1)",
  },
}
const variantsSiteTitle = {
  menuHidden: { opacity: 0 },
  menuVisible: { opacity: 1 },
}
const variantsMenu = {
  menuHidden: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: 1,
      duration: 0.4,
      type: "sping",
    },
  },
  menuVisible: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
      duration: 0.6,
    },
  },
}

const variantsMenuItems = {
  menuHidden: {
    y: -80,
    opacity: 0,
  },
  menuVisible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      ease: [0.17, -0.05, 0.26, 0.67],
    },
  },
}
// GREEN
const getHeightBasedOnThirdLevelsCount = thirdLevelsCount => {
  if (thirdLevelsCount === 0) return "auto"
  if (thirdLevelsCount === 1) return "84px"
  if (thirdLevelsCount === 2) return "120px"
  if (thirdLevelsCount === 3) return "157px"
  if (thirdLevelsCount === 4) return "193px"
  if (thirdLevelsCount === 5) return "230px"
  if (thirdLevelsCount === 6) return "265px"
  if (thirdLevelsCount === 7) return "301px"
  if (thirdLevelsCount === 8) return "337px"
  if (thirdLevelsCount === 9) return "372px"
  if (thirdLevelsCount === 10) return "408px"
  return "auto"
}

//* ---------------------------
//* Component  DesktopNavigation
//* ---------------------------

function DesktopNavigation({ menuItems, pageTemplate }) {
  const stateContext = useStateContext()
  const [menuHidden, setMenuHidden] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(true)
  const { location } = history

  const [clickedSubItem, setClickedSubItem] = useState(null)
  const [hoveredItem, setHoveredItem] = useState(null)
  const [hoveredThirdItem, setHoveredThirdItem] = useState(null)

  const [thirdLevelsCount, setThirdLevelsCount] = useState(0)
  const [secondLevelsCount, setSecondLevelsCount] = useState(0)

  let oldScrollY = 0
  const handelMenuVisibility = () => {
    setMenuHidden(oldScrollY < window.scrollY && window.scrollY >= 200 ? true : false)
    setScrollOffset(window.scrollY >= 200 ? false : true)
    oldScrollY = window.scrollY
  }
  const onWindowScroll = throttle(handelMenuVisibility, 50, { trailing: true })
  useEffect(() => {
    isBrowser() && window.addEventListener("scroll", onWindowScroll)
    return () => isBrowser() && window.removeEventListener("scroll", onWindowScroll)
  }, [])

  const navigateToRoute = path => {
    navigate(path)
  }

  const contactPopUpOpen = () => {
    stateContext.dispatch({ action: "SET_CONTACT_POP_UP", data: true })
    document.body.style.overflow = "hidden"
  }

  const getActiveSecondLevel = () => {
    for (const item of menuItems) {
      for (const subItem of item.childItems.nodes) {
        if (subItem.childItems.nodes.some(thirdItem => location.pathname === thirdItem.path)) {
          return subItem.id
        }
      }
    }
    return null
  }

  const activeSecondLevel = getActiveSecondLevel()

  const handleClick = (e, hasChildren) => {
    if (hasChildren) {
      e.preventDefault()
    }
  }

  return (
    <DesktopNavigationContainer
      initial="menuVisible"
      animate={
        (menuHidden && stateContext.state.menuMode === "dark") || (scrollOffset && stateContext.state.menuMode === "dark")
          ? "menuHiddenDark"
          : (menuHidden && stateContext.state.menuMode !== "dark") || (scrollOffset && stateContext.state.menuMode !== "dark")
          ? "menuHidden"
          : !menuHidden && stateContext.state.menuMode === "dark"
          ? "menuVisibleDark"
          : "menuVisible"
      }
      variants={variantsNavigationContainer}
      className={menuHidden && "pointer-events-none"}
    >
      <SiteTitle animate={menuHidden ? "menuHidden" : "menuVisible"} variants={variantsSiteTitle} css={stateContext.state.menuMode === "dark" ? tw`text-white` : tw`text-black`}>
        <Link to="/">Schmitts Katze</Link>
      </SiteTitle>
      <div onClick={e => navigateToRoute("/")}>
        <Logo isDark={true} enlarged={true} isMobile={false} />
      </div>
      {pageTemplate !== "No Navi" && (
        <Nav css={stateContext.state.menuMode === "dark" ? tw`text-white` : tw`text-black`}>
          <Ul animate={menuHidden ? "menuHidden" : "menuVisible"} variants={variantsMenu}>
            {menuItems.map(item => {
              const hasThirdLevelItems = item.childItems.nodes.some(subItem => subItem.childItems.nodes.length > 0)
              return (
                <Li
                  variants={variantsMenuItems}
                  key={item.id}
                  className={`${location.pathname === item.path ? "menu-active" : ""} ${item.childItems.nodes.length > 0 ? "has-child" : ""}`}
                  onMouseEnter={function () {
                    setHoveredItem(item)
                    setThirdLevelsCount(0)
                    setSecondLevelsCount(item.childItems.nodes.length)
                    item.childItems.nodes.map(subItem => {
                      subItem.childItems.nodes.map(thirdItem => {
                        if (location.pathname === thirdItem.path) {
                          setClickedSubItem(subItem)
                          setThirdLevelsCount(subItem.childItems.nodes.length)
                          setHoveredItem(item)
                        }
                      })
                    })
                  }}
                  onMouseLeave={function () {
                    setHoveredItem(null)
                    setClickedSubItem(null)
                  }}
                >
                  <Link to={item.path}>{item.label}</Link>

                  {item.childItems && item.childItems.nodes.length > 0 && (
                    <NestedNavigationWrapper
                      css={hoveredItem && hoveredItem.id === item.id ? tw`block` : tw`hidden`}
                      className={`${hasThirdLevelItems ? "" : "only-second-level"} ${"third-levels-count-" + thirdLevelsCount}`}
                      style={{
                        height: thirdLevelsCount > secondLevelsCount ? getHeightBasedOnThirdLevelsCount(thirdLevelsCount) : "max-content",
                      }}
                    >
                      <SecondLevelNavigation className={hasThirdLevelItems ? "" : "only-second-level-nav"}>
                        {item.childItems.nodes.map(subItem => {
                          const subItemHasChildren = subItem.childItems.nodes.length > 0
                          return (
                            <div
                              key={subItem.id}
                              css={tw`flex items-center justify-between`}
                              onMouseEnter={function () {
                                setClickedSubItem(subItem)
                                setSecondLevelsCount(item.childItems.nodes.length)
                                setThirdLevelsCount(subItem.childItems.nodes.length)
                              }}
                              onChange={() => (activeSecondLevel === subItem.id ? setClickedSubItem(subItem) : null)}
                            >
                              <Link
                                to={subItem.path}
                                className={`${activeSecondLevel === subItem.id ? "second-level-active" : ""} ${location.pathname === subItem.path ? "second-level-active" : ""} ${
                                  clickedSubItem && clickedSubItem.id === subItem.id ? "second-level-active" : ""
                                }`}
                              >
                                {subItem.label}
                              </Link>
                              {subItemHasChildren && (
                                <ThirdLevelNavigation
                                  css={clickedSubItem && clickedSubItem.id === subItem.id ? tw`block` : tw`hidden`}
                                  onMouseEnter={() => setClickedSubItem(subItem)}
                                  onMouseLeave={() => setClickedSubItem(null)}
                                >
                                  {subItem.childItems.nodes.map(thirdItem => (
                                    <div key={thirdItem.id} css={tw`mt-2 mb-3`} onMouseEnter={() => setHoveredThirdItem(thirdItem)} onMouseLeave={() => setHoveredThirdItem(null)}>
                                      <Link
                                        to={thirdItem.path}
                                        className={`${hoveredThirdItem && hoveredThirdItem.id === thirdItem.id ? "third-level-active" : ""} ${location.pathname === thirdItem.path ? "third-level-active" : ""}`}
                                      >
                                        {thirdItem.label}
                                      </Link>
                                    </div>
                                  ))}
                                </ThirdLevelNavigation>
                              )}
                            </div>
                          )
                        })}
                      </SecondLevelNavigation>
                    </NestedNavigationWrapper>
                  )}
                </Li>
              )
            })}
          </Ul>
        </Nav>
      )}
    </DesktopNavigationContainer>
  )
}

export default DesktopNavigation
